<template>
  <div class="checkTaskDetail-box">
    <!-- 任务状态一栏 -->
    <div style="display: flex">
      <div
        class="taskStatusItem"
        v-for="(item, i) in taskStatusList"
        :key="item.name"
        :class="item.isSelect ? 'selected' : ''"
        @click="handleSelectTaskStatus(item, i)"
      >
        <span>{{ item.name }}（{{ item.num }}）</span>
      </div>
    </div>
    <!-- 表格一栏 -->
    <el-row style="margin: 20px 0">
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableDataList"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          label="执行学校名称"
          prop="sysOrgSchoolName"
        ></el-table-column>
        <el-table-column label="所属区县" prop="sysOrgAreaName"></el-table-column>
        <!-- <el-table-column label="盘点总数" prop="schoolName"></el-table-column>
        <el-table-column label="待盘数" prop="schoolName"></el-table-column>
        <el-table-column label="已盘数" prop="schoolName"></el-table-column> -->
        <el-table-column
          label="实验室数量"
          prop="eqptStockSummary.sysOrgSchoolRoomCount"
        ></el-table-column>
        <el-table-column
          label="仪器耗材配齐率"
          prop="eqptStockSummary.materialRate"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          label="提交时间"
          prop="taskSubmitTime"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="taskSubmitStatus"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="操作" prop="schoolName">
          <template slot-scope="scope">
            <span class="blueBtn1" @click="handleCheckDetail(scope.row)"
              >查看详情</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div style="text-align: center">
      <el-button
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
    </div>
    <el-dialog title="采购需求清单详情" :visible.sync="dialogVisible">
      <div class="middleBoxSide" style="padding: 0 20px">
        <div class="schoolTitle">{{ schoolName }}：</div>
        <div>
          <div style="margin-bottom: 6px">提交人：{{ submitterName ? submitterName : '/' }}</div>
          <div>提交时间：{{ taskSubmitTime | dayFilters }}</div>
        </div>
      </div>
      <el-row style="margin-top: 20px">
        <el-table
          :data="checkRecordTableData"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          height="500px"
        >
          <el-table-column
            label="国标编号"
            prop="internationCode"
          ></el-table-column>
          <el-table-column label="名称" prop="materialName"></el-table-column>
          <el-table-column label="是否消耗品" prop="materialHasConsumables">
            <template slot-scope="scope">
              <span>{{ scope.row.materialHasConsumables ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否危化品" prop="materialHasDanger">
            <template slot-scope="scope">
              <span>{{ scope.row.materialHasDanger ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="规格型号功能"
            prop="materialSpecifications"
          ></el-table-column>
          <el-table-column label="单位" prop="materialUnit"></el-table-column>
          <el-table-column
            label="参考单价"
            prop="materialUnitPrice"
          ></el-table-column>
          <el-table-column label="标准数量" prop="schoolName">
            <template slot-scope="scope">
              <span
                >{{ scope.row.materialMinStandardNum }}~{{
                  scope.row.materialMaxStandardNum
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="库存数量" prop="stockNum"></el-table-column>
          <el-table-column
            label="缺口数量"
            prop="shortageNum"
          ></el-table-column>
          <el-table-column
            label="采购数量"
            prop="purchaseNum"
          ></el-table-column>
        </el-table>
      </el-row>
      <!-- 分页组件 -->
      <!-- <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangePageInfo"
          @current-change="handleCurrentChangePageInfo"
          :current-page="pageInfo.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.pageTotal"
        ></el-pagination>
      </el-row> -->
      <div style="text-align: right">
        <el-button type="primary" @click="handleDownloadPurchaseList"
          >下载采购需求清单</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPucchasePlanDetailApi,
  getPurchasePlanSummitStatusApi,
} from "@/api/taskManage/purchasePlan.js";
import { getPurchaseDataListApi } from "@/api/materialManage/purchase.js";
import { isEmpty, formatDate } from "@/utils/util";
import dayjs from "dayjs";
export default {
  name: "checkTaskDetail",
  data() {
    return {
      taskInfo: "",
      taskStatusList: [
        {
          name: "全部",
          num: 0,
          isSelect: true,
        },
        {
          name: "未提交",
          num: 0,
          isSelect: false,
        },
        {
          name: "已提交",
          num: 0,
          isSelect: false,
        },
      ],
      tableDataList: [],
      dialogVisible: false,
      checkRecordTableData: [],
      pageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      schoolName: "",
      taskSubmitTime: "",
      submitterName:"",
      status: "",
    };
  },
  created() {
    this.taskInfo = JSON.parse(this.$route.query.taskInfo);
    this.fetchTaskDataList(this.taskInfo.planId, this.status);
    this.fetchSummitStatusData(this.taskInfo.planId);
  },
  methods: {
    // 获取任务数据
    fetchTaskDataList(planId, status) {
      getPucchasePlanDetailApi(planId, status).then((res) => {
        if (res.success) {
          this.tableDataList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取提交数据
    fetchSummitStatusData(planId) {
      getPurchasePlanSummitStatusApi(planId).then((res) => {
        if (res.success) {
          this.taskStatusList.forEach((it, i) => {
            if (i == 0) it.num = res.data.totalCount;
            if (i == 1) it.num = res.data.unSubmitCount;
            if (i == 2) it.num = res.data.submitCount;
          });
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取盘点任务详情数据
    fetchCheckTaskDatailData(taskId) {
      getPurchaseDataListApi(taskId).then((res) => {
        if (res.success) {
          this.checkRecordTableData = res.data;
          // this.pageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 选择任务状态
    handleSelectTaskStatus(item, i) {
      this.taskStatusList.forEach((item) => {
        item.isSelect = false;
      });
      this.taskStatusList[i].isSelect = true;
      if(i == 0){
        this.status = ""
      }else {
        this.status = i 
      }
      this.fetchTaskDataList(this.taskInfo.planId, this.status);
    },
    // 点击查看详情
    handleCheckDetail(row) {
      this.fetchCheckTaskDatailData(row.taskId);
      this.schoolName = row.sysOrgSchoolName;
      this.taskSubmitTime = row.taskSubmitTime;
      this.submitterName=row.submitterName;
      this.dialogVisible = true;
    },
    handleSizeChangePageInfo(val) {},
    handleCurrentChangePageInfo(val) {},
    handleDownloadPurchaseList() {},
    // 格式化
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "startTime":
          return formatDate(new Date(row.startTime), "yyyy-MM-dd");
        case "endTime":
          return formatDate(new Date(row.endTime), "yyyy-MM-dd");
        case "taskSubmitTime":
          if(row.taskSubmitTime===null){
            return "";
          }
          return formatDate(new Date(row.taskSubmitTime), "yyyy-MM-dd");
        case "taskSubmitStatus":
          switch (row.taskSubmitStatus) {
            case 1:
              return "待提交";
            case 2:
              return "已提交";
          }
        case "eqptStockSummary.materialRate":
           return (row.eqptStockSummary.materialRate * 100).toFixed(2) + "%";  
      }
    },
  },
  filters: {
    dayFilters(val) {
      return val ? dayjs(val).format("YYYY年MM月DD日 HH:mm:ss") : "/";
    },
  },
};
</script>

<style lang="scss" scoped>
.taskStatusItem {
  width: 122px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  span {
    font-size: 14px;
  }
  &.selected {
    border-color: rgb(84, 173, 255);
    color: rgb(84, 173, 255);
  }
}
.taskStatusItem:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.taskStatusItem:nth-child(4) {
  border-radius: 0 5px 5px 0;
}
.blueBtn1 {
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}
.schoolTitle {
  border-left: 4px solid #00f;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: 700;
}
</style>
