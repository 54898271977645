// 采购需求收集计划模块
import { request, noTimeOutReq } from "@/utils/request.js";

// 新增【采购计划】
export function addPurchasePlanApi(data) {
  return request({
    url: `/eqpt/stock/purchase/plan/add`,
    method: "post",
    data,
  });
}

// 删除【采购计划】
export function deletePurchasePlanApi(planId) {
  return request({
    url: `/eqpt/stock/purchase/plan/delete`,
    method: "post",
    params: {
      planId,
    },
  });
}

// 获取【采购计划】详情
export function getPurchasePlanDetailApi(planId) {
  return request({
    url: `/eqpt/stock/purchase/plan/get`,
    method: "post",
    params: {
      planId,
    },
  });
}

// 分页展示所有【采购计划】
export function getPurchasePlanListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/purchase/plan/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【采购计划】
export function updatePurchasePlanApi(data) {
  return request({
    url: `/eqpt/stock/purchase/plan/update`,
    method: "post",
    data,
  });
}

// 展示计划下的采购任务
export function getPucchasePlanDetailApi(planId, status) {
  return request({
    url: `/eqpt/stock/purchase/plan/task/list`,
    method: "post",
    params: {
      planId,
      status,
    },
  });
}

// 采购计划状态统计
export function getPucchasePlanStatusApi() {
  return request({
    url: `/eqpt/stock/purchase/plan/status/count`,
    method: "post",
  });
}

// 采购任务状态统计
export function getPurchasePlanSummitStatusApi(planId) {
  return request({
    url: `/eqpt/stock/purchase/task/status/count/${planId}`,
    method: "post",
  });
}
