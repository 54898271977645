var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkTaskDetail-box"},[_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.taskStatusList),function(item,i){return _c('div',{key:item.name,staticClass:"taskStatusItem",class:item.isSelect ? 'selected' : '',on:{"click":function($event){return _vm.handleSelectTaskStatus(item, i)}}},[_c('span',[_vm._v(_vm._s(item.name)+"（"+_vm._s(item.num)+"）")])])}),0),_c('el-row',{staticStyle:{"margin":"20px 0"}},[_c('el-table',{attrs:{"header-cell-style":{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      },"cell-style":{ 'text-align': 'center' },"data":_vm.tableDataList}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"执行学校名称","prop":"sysOrgSchoolName"}}),_c('el-table-column',{attrs:{"label":"所属区县","prop":"sysOrgAreaName"}}),_c('el-table-column',{attrs:{"label":"实验室数量","prop":"eqptStockSummary.sysOrgSchoolRoomCount"}}),_c('el-table-column',{attrs:{"label":"仪器耗材配齐率","prop":"eqptStockSummary.materialRate","formatter":_vm.customFormatter}}),_c('el-table-column',{attrs:{"label":"提交时间","prop":"taskSubmitTime","formatter":_vm.customFormatter}}),_c('el-table-column',{attrs:{"label":"状态","prop":"taskSubmitStatus","formatter":_vm.customFormatter}}),_c('el-table-column',{attrs:{"label":"操作","prop":"schoolName"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"blueBtn1",on:{"click":function($event){return _vm.handleCheckDetail(scope.row)}}},[_vm._v("查看详情")])]}}])})],1)],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{on:{"click":() => {
          this.$router.back();
        }}},[_vm._v("返回")])],1),_c('el-dialog',{attrs:{"title":"采购需求清单详情","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"middleBoxSide",staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"schoolTitle"},[_vm._v(_vm._s(_vm.schoolName)+"：")]),_c('div',[_c('div',{staticStyle:{"margin-bottom":"6px"}},[_vm._v("提交人："+_vm._s(_vm.submitterName ? _vm.submitterName : '/'))]),_c('div',[_vm._v("提交时间："+_vm._s(_vm._f("dayFilters")(_vm.taskSubmitTime)))])])]),_c('el-row',{staticStyle:{"margin-top":"20px"}},[_c('el-table',{attrs:{"data":_vm.checkRecordTableData,"header-cell-style":{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        },"cell-style":{ 'text-align': 'center' },"height":"500px"}},[_c('el-table-column',{attrs:{"label":"国标编号","prop":"internationCode"}}),_c('el-table-column',{attrs:{"label":"名称","prop":"materialName"}}),_c('el-table-column',{attrs:{"label":"是否消耗品","prop":"materialHasConsumables"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.materialHasConsumables ? "是" : "否"))])]}}])}),_c('el-table-column',{attrs:{"label":"是否危化品","prop":"materialHasDanger"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.materialHasDanger ? "是" : "否"))])]}}])}),_c('el-table-column',{attrs:{"label":"规格型号功能","prop":"materialSpecifications"}}),_c('el-table-column',{attrs:{"label":"单位","prop":"materialUnit"}}),_c('el-table-column',{attrs:{"label":"参考单价","prop":"materialUnitPrice"}}),_c('el-table-column',{attrs:{"label":"标准数量","prop":"schoolName"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.materialMinStandardNum)+"~"+_vm._s(scope.row.materialMaxStandardNum))])]}}])}),_c('el-table-column',{attrs:{"label":"库存数量","prop":"stockNum"}}),_c('el-table-column',{attrs:{"label":"缺口数量","prop":"shortageNum"}}),_c('el-table-column',{attrs:{"label":"采购数量","prop":"purchaseNum"}})],1)],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDownloadPurchaseList}},[_vm._v("下载采购需求清单")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }