import { request, noTimeOutReq } from "@/utils/request.js";

// 添加仪器耗材-添加采购清单明细
export function addMaterialForPurchaseApi(taskId, data) {
  return request({
    url: `/eqpt/stock/purchase/task/detail/add`,
    method: "post",
    params: {
      taskId,
    },
    data,
  });
}

// 清空采购清单
export function emptyPurchaseListApi(taskId) {
  return request({
    url: `/eqpt/stock/purchase/task/detail/clear`,
    method: "post",
    params: {
      taskId,
    },
  });
}

// 一键生成采购清单
export function generatePurchaseListApi(taskId, sysOrgSchoolId) {
  return request({
    url: `/eqpt/stock/purchase/task/detail/generate/${sysOrgSchoolId}`,
    method: "post",
    params: {
      taskId,
    },
  });
}

// 采购清单数据
export function getPurchaseDataListApi(taskId) {
  return request({
    url: `/eqpt/stock/purchase/task/detail/list`,
    method: "post",
    params: {
      taskId,
    },
  });
}

// 分页展示【学校采购任务】
export function getSchoolPurchaseTaskListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/purchase/task/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 提交采购清单
export function submitPurchaseListApi(taskId, data) {
  return request({
    url: `/eqpt/stock/purchase/task/submit`,
    method: "post",
    params: {
      taskId,
    },
    data,
  });
}
